<template>
  <div id="recaptcha-container"></div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';

interface RecaptchaProps {
  sitekey: string;
  callback: (token: string) => void;
  validate?: () => boolean;
  badge?: string;
  type?: string;
  tabindex?: string;
  size?: string;
}

const props = defineProps<RecaptchaProps>();

const recaptchaId = ref<number>(0);

if (typeof window !== 'undefined') {
  (window as any).recaptchaLoaded = new Promise<void>((resolve) => {
    (window as any).vueRecaptchaInit = resolve;
  });
  const recaptchaScript = document.createElement('script');
  recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaInit&render=explicit');
  recaptchaScript.setAttribute('async', '');
  recaptchaScript.setAttribute('defer', '');
  document.body.appendChild(recaptchaScript);
}

onMounted(() => {
  if (typeof window === 'undefined') return;

  (window as any).recaptchaLoaded.then(() => {
    try {
      const options: Record<string, any> = {
        sitekey: props.sitekey,
      };
      if (props.badge) options.badge = props.badge;
      if (props.type) options.type = props.type;
      if (props.tabindex) options.tabindex = props.tabindex;
      if (!props.size) {
        options.size = 'checkbox';
        options.callback = getToken;
      }

      const recaptchaDiv = document.createElement('div');
      recaptchaDiv.className = 'g-recaptcha';
      document.querySelector('#recaptcha-container')?.appendChild(recaptchaDiv);

      recaptchaId.value = (window as any).grecaptcha.render(recaptchaDiv, options);
    } catch (e) {
      console.error(e);
    }
  });
});

function submitData(event: Event) {
  event.preventDefault();
  if (typeof window === 'undefined') return;

  if (props.validate?.() || props.validate === undefined) {
    if (!props.size) {
      (window as any).grecaptcha.execute(recaptchaId.value);
    } else {
      getToken((window as any).grecaptcha.getResponse(recaptchaId.value));
    }
  }
}

function getToken(token: string) {
  if (typeof window === 'undefined') return;
  recaptchaId.value = Number(token);
  props.callback(token);
}
</script>
