<template>
  <label class="mySelect" :for="props.name || ''">
    <input
      ref="inputSelectRef"
      type="text"
      placeholder="Selecione"
      @input="onInputChange"
      @blur="onBlur"
      :id="props.name"
      :name="props.name"
      :value="value"
      autocomplete="off"
    />
    <span></span>
    <div>
      <ul>
        <slot/>
      </ul>
    </div>
  </label>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits } from 'vue'

const inputSelectRef = ref<HTMLInputElement | null>(null)

const props = defineProps<{
    name: string;
    for?: string;
    value: string;
  }>()

const emit = defineEmits<{
    (event: 'update:modelValue', value: string, listName: string): void;
    (event: 'blur', value: string): void;
  }>()

const onInputChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  const newValue = target.value
  emit('update:modelValue', newValue, props.name)
}

const onBlur = (event: Event) => {
  const target = event.target as HTMLInputElement
  const value = target.value
  emit('blur', value)
}
</script>

<style lang="scss" scoped>

    .mySelect{
      position: relative;
      input{
        width: 100%;
        height: 55px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        transition: border-color .2s, color .2s, background .2s;
        cursor: text;
        &:focus{
          outline: none;
          border: 2px solid $fluxoo-primary-color !important;
          color: $fluxoo-primary-color !important;
          padding: 14px 19px !important;
          &::placeholder{
            color: $fluxoo-primary-color !important;
          }
          & ~ span{
            top: 24px;
            border-color: $fluxoo-primary-color;
            transform: rotate(405deg);
          }
          & ~ div{
            display: flex;
          }
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
      }
      div{
        min-width: 100%;
        max-height: 233px;
        padding: 5px 10px 5px 0;
        display: none;
        flex-direction: column;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10);
        box-sizing: border-box;
        position: absolute;
        top: calc(100% + 5px);
        z-index: 1;
        ul{
          width: 100%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding: 15px 20px;
          margin: 0;
          overflow-y: auto;
          row-gap: 24px;
          &::-webkit-scrollbar{
            width: 8px;
            background: #FFF;
          }
          &::-webkit-scrollbar-thumb{
            background: $fluxoo-primary-color;
            border-radius: 8px;
          }
          li{
            display: flex;
            align-items: center;
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: start;
            transition: color .2s;
            cursor: pointer;
            &:hover{
              color: $fluxoo-primary-color;
              text-decoration: underline
            }
          }
        }
      }
      span{
        content: "";
        width: 10px;
        min-width: 10px;
        height: 10px;
        min-height: 10px;
        border-left: 2px solid #292D32;
        border-top: 2px solid #292D32;
        border-radius: 2px;
        position: absolute;
        top: 19px;
        right: 30px;
        transform: rotate(225deg);
        transition: border-color .2s;
        cursor: text;
      }
      &::before{
        display: none;
      }
    }
</style>
