import { ref } from 'vue'

export const isNotificationsModalOpen = ref<boolean>(false)
export const newNotification = ref<boolean>(false)

export const viewNotification = (action: boolean) => {
  localStorage.setItem('newNotification', 'false');
  newNotification.value = !action;
}

export const checkLocalStorageNotification = () => {
  const notification = localStorage.getItem('newNotification');

  if (notification == 'true') {
    viewNotification(false);
  } else {
    viewNotification(true);
  }
};
