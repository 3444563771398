<template>
  <label class="tag-input" :for="name" :id="`${name}-input`">
    <span></span>
    <input
      v-if="!disabled"
      :name="name"
      :id="name"
      v-model="inputVal"
      @input="bindOnChange"
      @keydown="checkBackspaceOnEmpty"
      :placeholder="placeholder"
    />
  </label>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps } from 'vue'

const props = defineProps<{
  name: string;
  placeholder: string;
  value?: string[];
  disabled?: boolean
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>()
const tags = ref<string[]>(props.value || [])
const inputVal = ref<string>('')

const renderTags = () => {
  const tagTarget = document.querySelector(`#${props.name}-input span`)
  if (tagTarget) {
    tagTarget.innerHTML = ''
    tags.value.forEach((tag) => {
      const tagElement = constructTag(tag)
      tagTarget.appendChild(tagElement)
    })
  }
}

const constructTag = (tag: string) => {
  const elem = document.createElement('span')
  elem.classList.add('tag')
  elem.innerText = tag.trim()
  elem.dataset.tag = tag

  if (!props.disabled) {
    const close = document.createElement('a')
    close.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 5L5 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 5L15 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
    close.classList.add('close')
    close.href = '#'
    close.dataset.tag = tag
    close.addEventListener('click', removeTag)

    elem.appendChild(close)
    elem.addEventListener('click', togglePopOverEdit)
  }

  return elem
}

const addTag = (tagName: string) => {
  if (tagName.trim() === '' || checkDuplication(tagName)) {
    return
  }
  tags.value.push(tagName.toLowerCase())
  renderTags()
  emit('update:modelValue', tags.value)
}

const bindOnChange = (e: Event) => {
  const input = e.target as HTMLInputElement
  const val = input.value
  const lastChar = val.substr(val.length - 1)

  if (lastChar === ',') {
    const tagName = val.replace(',', '').trim()
    input.value = ''
    inputVal.value = ''
    addTag(tagName)
  }
}

const handleKeyPress = (e: KeyboardEvent) => {
  const input = e.target as HTMLInputElement
  if (e.key === 'Enter') {
    e.preventDefault()
    const tagName = input.value.trim()
    if (tagName !== '') {
      addTag(tagName)
      input.value = ''
      inputVal.value = ''
    }
  }
}

const checkDuplication = (tag: string) => tags.value.includes(tag)

const checkBackspaceOnEmpty = (e: KeyboardEvent) => {
  if (tags.value.length > 0 && (e.key === 'Backspace' || e.key === 'Delete')) {
    const input = e.target as HTMLInputElement
    if (input.value === '') {
      const lastVal = tags.value.pop()
      if (lastVal) {
        input.value = lastVal + ' '
        renderTags()
      }
    }
  }
}

const removeTag = (e: any) => {
  e.preventDefault()
  const target = e.currentTarget as HTMLAnchorElement
  const tagToRemove = target.dataset.tag || ''
  const index = tags.value.indexOf(tagToRemove)

  if (index > -1) {
    tags.value.splice(index, 1)
    renderTags()
  }
}

const togglePopOverEdit = (e: Event) => {
  const target = e.currentTarget as HTMLElement
  const hasPopOver = target.querySelector('#pop-over-edit')
  const popOver = document.createElement('div')
  const popOverInput = document.createElement('input')

  if (hasPopOver) {
    target.removeChild(hasPopOver)
    return
  }

  if (!hasPopOver && target.nodeName === 'SPAN') {
    const curValue = target.dataset.tag || ''
    popOverInput.value = curValue
    popOver.setAttribute('id', 'pop-over-edit')
    popOverInput.setAttribute('name', 'tag-edit')
    popOverInput.setAttribute('id', 'tag-edit')
    popOverInput.addEventListener('input', (e: Event) => {
      bindPopOverInput(e, curValue)
    })

    popOver.appendChild(popOverInput)
    target.appendChild(popOver)
  }
}

const bindPopOverInput = (e: Event, tag: string) => {
  const input = e.target as HTMLInputElement
  const val = input.value
  const index = tags.value.indexOf(tag)

  if (val !== '') {
    if (index > -1) {
      tags.value[index] = val
      const tagElement = document.querySelector(`.tag[data-tag="${tag}"]`) as HTMLElement
      if (tagElement) {
        tagElement.innerText = val
        tagElement.dataset.tag = val
      }
    }
  } else {
    removeTag({ currentTarget: { dataset: { tag } } })
  }
}

onMounted(() => {
  const inputTarget = document.querySelector(`#${props.name}`) as HTMLInputElement
  if (inputTarget) {
    inputTarget.addEventListener('input', bindOnChange)
    inputTarget.addEventListener('keydown', handleKeyPress)
    inputTarget.addEventListener('keydown', checkBackspaceOnEmpty)
  }
  renderTags()
})
</script>

<style lang="scss">
  .tag-input {
    min-height: 55px;
    background: transparent;
    padding: 5px 10px 6px 10px;
    border-radius: 14px;
    box-sizing: border-box;
    border: 1px solid #D3D3D3;
    cursor: text;
    span {
      display: inline-block;
    }
    .tag {
      padding: 8px 15px 8px 15px;
      margin: 5px 5px 0 0;
      border-radius: 30px;
      display: inline-block;
      background: $fluxoo-primary-color;
      color: #FFF;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      text-transform: capitalize;
      transition: background 250ms ease-in-out;
      position: relative;
      &:hover {
        background: darken($fluxoo-primary-color, 5%);
      }
      .close {
        cursor: pointer;
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        margin: 0 0 0 6px;
        position: absolute;
        right: 6px;
        bottom: 7px;
      }
      #pop-over-edit {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: 5px;
        input {
          background: white;
          border-radius: 5px;
          box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
          padding: 5px;
          margin: 0;
        }
      }
    }
    input {
      height: 30px;
      display: inline-block;
      margin: 5px 0 0 5px;
      background: none;
      border: none;
      color: $fluxoo-primary-color;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      &:focus {
        outline: none;
      }
      &::placeholder{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: color .2s;
      }
    }
  }
</style>
