<template>
<div class="fx-notification" id="fx-notification">
  <div class="content-fx-notification">
    <div class="fx-notification__icon-wrapper">
      <figure>
        <svg width="63" height="64" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="vuesax/bulk/direct-notification">
            <g id="direct-notification">
              <path id="Vector" d="M49.875 21.375C54.2242 21.375 57.75 17.8492 57.75 13.5C57.75 9.15076 54.2242 5.625 49.875 5.625C45.5258 5.625 42 9.15076 42 13.5C42 17.8492 45.5258 21.375 49.875 21.375Z" fill="#079F72"/>
              <path id="Vector_2" opacity="0.4" d="M49.875 25.3125C43.365 25.3125 38.0625 20.01 38.0625 13.5C38.0625 11.61 38.5613 9.85125 39.3488 8.25H19.74C10.6837 8.25 5.25 13.6575 5.25 22.74V43.6087C5.25 52.7175 10.6837 58.125 19.74 58.125H40.6087C49.6912 58.125 55.0988 52.7175 55.0988 43.635V24.0263C53.5238 24.8138 51.765 25.3125 49.875 25.3125Z" fill="#D3D3D3"/>
              <path id="Vector_3" d="M53.3925 33.765H44.7038C42.2625 33.765 40.0838 35.13 38.9813 37.2825L36.8813 41.43C36.3825 42.4275 35.385 43.0313 34.2825 43.0313H26.1187C25.3575 43.0313 24.2288 42.8738 23.52 41.4038L21.4463 37.2825C20.3438 35.1038 18.165 33.7388 15.7237 33.7388H6.9825C6.0375 33.765 5.25 34.5525 5.25 35.4975V43.6088C5.25 52.7175 10.6838 58.125 19.7663 58.125H40.6613C49.2188 58.125 54.495 53.4263 55.125 45.105V35.4975C55.125 34.5525 54.3375 33.765 53.3925 33.765Z" fill="#079F72"/>
            </g>
          </g>
        </svg>
      </figure>
    </div>
    <div class="fx-notification__text">
      <h6>{{ notification.title }}</h6>
      <p>{{ notification.body }}</p>
    </div>
    <a href="" id="fx-notification-link">Ver mais</a>
  </div>
</div>
</template>

<script lang="ts" setup>
import notificationAudio from '@/assets/audio/notifications/notification.mp3';
import { viewNotification } from '@/global/states/ModalState';
import { ref } from 'vue';

const notification = ref({
  title: '',
  body: ''
})

function show(title: string, body: string, href: string ) {
  notification.value = { title, body }

  document.getElementById('fx-notification')?.classList.add('open');
  (document.getElementById('fx-notification-link') as HTMLAnchorElement)!.href = href;

  const closeTimeout = setTimeout(() => {
    document.getElementById('fx-notification')?.classList.remove('open')
    clearTimeout(closeTimeout)
  }, 2000);

  const audio = new Audio(notificationAudio);
  audio.play();

  localStorage.setItem('newNotification', 'true');
  viewNotification(true);
}

defineExpose({
  show,
});
</script>

<style lang="scss">
  .fx-notification {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 113px;
    max-width: 1276px;
    position: fixed;
    font-family: 'Inter', 'sans-serif';
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 1;
    align-items: center;
    transition: all .1s ease-in;
    bottom: -100%;
    box-sizing: border-box;
    padding: 0 0 0 76px;
    transition: bottom 1s;
    &.open {
      bottom: 15px;
    }
    .content-fx-notification{
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      background: #fff;
      border-radius: 24px;
      padding: 20px;
      display: flex;
      column-gap: 20px;
      figure {
        width: 63px;
        min-width: 63px;
        height: 63px;
        min-height: 63px;
        display: flex;
        margin: 0;
        svg{
          width: 63px;
          min-width: 63px;
          height: 63px;
          min-height: 63px;
        }
      }
      h6 {
        min-width: 202px;
        color: #292F31;
        font-family: 'Nunito', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin: 0;
      }
      p {
        color: #292F31;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
      .fx-notification__icon-wrapper {
        padding: 0 !important;
      }
      .fx-notification__text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
      }
      a {
        width: 100%;
        max-width: 261px;
        height: 55px;
        border-radius: 30px;
        background: $fluxoo-primary-color;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin: 0 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background .2s;
        &:hover {
          background: $fluxoo-primary-color-hover;
        }
      }
    }
  }

  @media(max-width: 1315px) {
    .fx-notification {
      max-width: unset;
      padding: 0 15px 0 91px;
    }
  }

  @media(max-width: 545px) {
    .fx-notification {
      max-width: unset;
      padding: 0 15px 0 91px;
      .content-fx-notification{
        padding: 15px;
        column-gap: 10px;
        .fx-notification__text{
          h6 {
            min-width: unset;
            font-size: 19px;
          }
          p{
            font-size: 12px;
          }
        }
      }
    }
  }

  @media(max-width: 425px) {
    .fx-notification {
      max-width: unset;
      padding: 0 15px;
      &.open{
        bottom: 96px;
      }
      .content-fx-notification{
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        h6{
          min-width: unset;
          font-size: 24px !important;
          text-align: center;
        }
        a{
          margin: 0;
        }
      }
    }
  }
</style>
