<template>
  <button :class="{ 'not-button': !isButton }" :style="spinnerStyles" class="simple-spinner">
    <span class="loader"></span>
  </button>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  height: {
    type: Number,
    default: null
  },
  maxWidth: {
    type: Number,
    default: null
  },
  isButton: {
    type: Boolean,
    default: true
  }
})

const spinnerStyles = {
  height: props.height ? props.height + 'px' : 'unset',
  maxWidth: props.maxWidth ? props.maxWidth + 'px' : 'unset'
}
</script>

<style scoped lang="scss">
@import '@/global/scss/variables.scss';

.simple-spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: $fluxoo-primary-color;
  &.not-button{
    background: transparent;
    .loader{
      border: 5px solid $fluxoo-primary-color;
      border-bottom-color: transparent;
    }
  }
}

.simple-spinner .loader {
  width: 30px;
  height: 30px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.8s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
