import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { useAxios } from "@/api/axios";
import NotificationListener from "../notifications/NotificationListener";
import { user as authenticatedUser } from "@/global/states/GlobalState";

const axios = useAxios();

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

onMessage(messaging, (payload: any) => {
  NotificationListener.listen(payload.data)
});

navigator.serviceWorker
  .register('/firebase-messaging-sw.js')
  .then((registration) => {
    getToken(messaging, {
      vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
    })
      .then((currentToken: any) => {
        if (currentToken) {
          if (!localStorage.getItem('fcm_token') || (authenticatedUser.value.name && (localStorage.getItem('fcm_token') !== authenticatedUser.value.fcm_token))) {
            axios
              .patch("/api/profile/fcm-token", {
                fcm_token: currentToken,
              })
              .then(() => {
                console.log("cm firebase token updated succesfully");
              });
          } else {
            localStorage.setItem("fcm_token", currentToken);
          }
        } else {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              console.log("Permissão para notificações concedida.");
            } else {
              console.log("Permissão para notificações negada.");
            }
          });
    
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err: any) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  })
  .catch((err) => {
    console.error('Service Worker registration failed', err);
  });

export default messaging;
