import DocumentLogActionEnum from "@/enums/DocumentLogActionEnum"
import DocumentStatusEnum from "@/enums/DocumentStatusEnum"
import { DocumentModel } from "@/models/DocumentModel"
import { OrganizationModel } from "@/models/OrganizationModel"
import { UserModel } from "@/models/UserModel"

export default function useDocument () {
  const isDocumentOpen = (document: DocumentModel) => {
    console.log(document.status)
    return document.status != DocumentStatusEnum.COMPLETED && document.status != DocumentStatusEnum.OVERDUE
  }

  const getAllowedUsersToTransfer = (authenticatedUser: Partial<UserModel>, document: DocumentModel | undefined, users: UserModel[] = [], organization?: OrganizationModel) => {
    return users.filter((user) => {
      // const isCurrentResponsible = document?.responsibles.find((responsible) => responsible.id === user.id)
      const isUserFromSelectedOrg = organization ? user.organization_id === organization.id : true
      // const isAuthenticatedUser = authenticatedUser.id === user.id

      return  isUserFromSelectedOrg/* && !isCurrentResponsible && !isAuthenticatedUser*/
    })
  }

  const getAbilities = (authenticatedUser: Partial<UserModel>, document?: DocumentModel) => {
    if (document) {
      const isDocumentCurrentResponsible = document.responsibles.find((responsible) => responsible.id === authenticatedUser.id)
      const isDocumentCreator = authenticatedUser.id == document.created_by
      const userParticipatesInDocumentFlow = document.logs.some((log) => {
        return log.responsibles.some((responsible) => responsible.id === authenticatedUser.id)
      })
      const isDocumentCompleted = document.status == DocumentStatusEnum.COMPLETED
      const documentCompletionLog = document.logs.find((log) => log.action == DocumentLogActionEnum.COMPLETION)
      const isDocumentCreatorOrFinisher = documentCompletionLog?.created_by === authenticatedUser.id || document.created_by === authenticatedUser.id || isDocumentCurrentResponsible
      const isOpen = isDocumentOpen(document)
  
      const transferDocument = (userParticipatesInDocumentFlow && isOpen) || (isDocumentCurrentResponsible && isDocumentCompleted) || isDocumentCreator
      const completeDocument = (isDocumentCurrentResponsible && isOpen) || isDocumentCreator
      const reopenDocument = !isOpen && isDocumentCreatorOrFinisher
      const extendDocument = isOpen && (isDocumentCreator || isDocumentCurrentResponsible)
  
      return {
        transferDocument,
        completeDocument,
        reopenDocument,
        extendDocument
      }
    }
  
    return {}
  }

  return {
    getAllowedUsersToTransfer,
    getAbilities,
    isDocumentOpen
  }
}