<template>
    <div id="edit-models" v-if="payload.id">
      <label label @click="$router.go(-1)" class="back">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
        <p>Voltar</p>
      </label>
      <section class="radio">
        <h1><strong>Tipo</strong> (obrigatório)</h1>
        <div>
          <label for="document_model">
            <input type="radio" name="type" id="document_model" :value="ModelTypeEnum.DOCUMENT_MODEL" v-model="payload.type">
            <span></span>
            <p>Modelo de documento</p>
          </label>
          <label for="flow_model">
            <input type="radio" name="type" :value="ModelTypeEnum.FLOW_MODEL" v-model="payload.type" id="flow_model">
            <span></span>
            <p>Modelo de fluxo</p>
          </label>
        </div>
      </section>
      <section>
        <h1><strong>Para qual setor</strong> (obrigatório)</h1>
        <h2>Somente os setores selecionados irão ter acesso a esse modelo.</h2>
        <MyOptions :currentValue="payload.organizations?.length ? payload.organizations.map(org => org.name).join(', ') : 'Selecione'">
          <div class="item" v-for="(org, index) in organizations" :key="index">
            <input type="checkbox" :id="'organization-' + index + 1" name="organizations" :checked="payload.organizations?.find((o) => o.id == org.id) ? true : false"
            @change="($event.target as HTMLInputElement).checked ? payload.organizations!.push(org) : payload.organizations = payload.organizations?.filter((o) => o.id != org.id)">
            <label :for="'organization-' + index + 1">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                  <g clip-path="url(#clip0_388_3128)">
                    <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_388_3128">
                      <rect width="12.5" height="10" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <p>{{ org.name }}</p>
            </label>
          </div>
        </MyOptions>
      </section>
      <section>
        <h1><strong>Tipo de arquivo</strong> (obrigatório)</h1>
        <MySelect :name="'model_file_mime_type'" :for="'model_file_mime_type'" :currentValue="payload.file_mime_type ? payload.file_mime_type : null">
          <li v-for="(type, index) in ModelFileMimeTypeEnum" :key="index" @mousedown="payload.file_mime_type = type;">{{type}}</li>
        </MySelect>
      </section>
      <section>
        <h1><strong>Nome do modelo</strong> (obrigatório)</h1>
        <input type="text" placeholder="Ex: Ofício de solicitação de férias" v-model="payload.name">
      </section>
      <section>
        <h1><strong>Descrição</strong> (obrigatório)</h1>
        <textarea name="" id="" cols="30" rows="10" placeholder="Ex: Modelo de ofício para solicitação de férias para todos os servidores sejam efetivo ou contratados." v-model="payload.description"></textarea>
      </section>
      <section>
        <h1><strong>Upload do modelo</strong> (obrigatório)</h1>
        <FileUploader :visualizeFile="visualizeFile" :files="payload.files!" :pushFile="(file) => payload.files!.push(file)" @delete="(file) => deleteFileIds.push(file.id)" />
      </section>
      <section class="spacing">
        <h1><strong>Status</strong> (obrigatório)</h1>
        <label class="stats" for="stats">
          <input type="checkbox" name="stats" id="stats" v-model="payload.status">
          <span></span>
        </label>
      </section>
      <nav>
        <input v-if="!isUpdatingDocument" type="button" value="Salvar" @click="updateModel" :disabled="!payload.type || !payload.organizations?.length || !payload.file_mime_type || !payload.name || !payload.description || !payload.files?.length">
        <SpinnerLoaderButton v-else :height="60" :maxWidth="464"/>
        <input type="button" value="Cancelar" @click="$router.go(-1)">
      </nav>
    </div>
    <SpinnerLoader v-else />
</template>

<script setup lang="ts">
import MySelect from '@/components/select/MySelect.vue'
import MyOptions from '@/components/options/MyOptions.vue'
import ModelFileMimeTypeEnum from '@/enums/ModelFileMimeTypeEnum'
import { onMounted, ref } from 'vue'
import { useAxios } from '@/api/axios'
import { OrganizationModel } from '@/models/OrganizationModel'
import ModelInterface, { ModelFileInterface } from '@/models/ModelInterface'
import ModelTypeEnum from '@/enums/ModelTypeEnum'
import { toast } from 'vue3-toastify'
import FileUploader from '@/components/input/FileUploader.vue'
import { AxiosResponse } from 'axios'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import ModelStatusEnum from '@/enums/ModelStatusEnum'

const axios = useAxios()
const props = defineProps<{ id: number }>()
const deleteFileIds = ref<number[]>([])
const payload = ref<Partial<ModelInterface>>({ organizations: [], files: [] })
const organizations = ref<OrganizationModel[]>()
const isUpdatingDocument = ref(false)

function getModel () {
  axios.get(`/api/models/${props.id}`)
    .then((res: AxiosResponse<ModelInterface>) => {
      payload.value = { ...res.data, status: res.data.status == ModelStatusEnum.ACTIVE }
    })
}

function getOrganizations () {
  axios.get('/api/organizations')
    .then((res: AxiosResponse<OrganizationModel[]>) => {
      organizations.value = res.data
    })
}

function updateModel () {
  isUpdatingDocument.value = true

  axios.put('/api/models', {
    type: payload.value.type,
    file_mime_type: payload.value.file_mime_type,
    name: payload.value.name,
    description: payload.value.description,
    files: payload.value.files!.filter((file) => file instanceof File),
    model_ids: [payload.value.id],
    organization_ids: payload.value.organizations!.map((org) => org.id),
    status: payload.value.status ? 'active' : 'inactive',
    delete_file_ids: deleteFileIds.value
  })
    .then((res: AxiosResponse<ModelInterface>) => {
      payload.value = { ...res.data, status: res.data.status == ModelStatusEnum.ACTIVE }
      deleteFileIds.value = []
      toast.success('Modelo editado com sucesso')
    })
    .finally(() => isUpdatingDocument.value = false)
}

async function visualizeFile (file: ModelFileInterface): Promise<string> {
  try {
    const response = await axios.get(`/api/models/get-file-content/${file.id}`, { responseType: 'blob' })
    return URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
  } catch (error) {
    console.error('Erro ao buscar o documento:', error)
    return ''
  }
}

onMounted(() => {
  getModel()
  getOrganizations()
})
</script>

<style lang="scss">
  #edit-models{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 28px;
    padding: 32px 0 0 0;
    section{
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .date-picker-label{
        position: relative;
        display: flex;
        align-items: center;
        input{
          display: flex;
          cursor: pointer;
          color: $fluxoo-primary-color;
        }
        svg{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          stroke: #090909;
          position: absolute;
          right: 22px;
        }
      }
      h1{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 13px 0;
        text-align: start;
        strong{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      h2{
        color: #6F6F6F;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        margin: -13px 0 13px 0;
      }
      input[type="text"]{
        width: 100%;
        height: 55px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: $fluxoo-primary-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        transition: border-color .2s, color .2s, background .2s;
        &:focus{
          outline: none;
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
      }
      textarea{
        width: 100%;
        height: 127px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: $fluxoo-primary-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        resize: none;
        transition: border-color .2s, color .2s, background .2s;
        &:focus{
          outline: none;
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
        &::-webkit-scrollbar{
          width: 0;
        }
      }
      &.radio{
        div{
          display: flex;
          align-items: center;
          column-gap: 20px;
          label{
            display: flex;
            align-items: center;
            column-gap: 8px;
            cursor: pointer;
            input{
              display: none;
              &:checked{
                & ~ span {
                  outline: 1px solid $fluxoo-primary-color;
                  border: 1px solid $fluxoo-primary-color;
                  &::before{
                    opacity: 1;
                    transform: scale(1.0);
                  }
                }
                & ~ p {
                  color: $fluxoo-primary-color;
                }
              }
            }
            span{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              border-radius: 50%;
              border: 1px solid #D5D5D5;
              box-sizing: border-box;
              position: relative;
              transition: border-color .2s;
              &::before{
                content: '';
                width: 12px;
                min-width: 12px;
                height: 12px;
                min-height: 12px;
                border-radius: 50%;
                background: $fluxoo-primary-color;
                position: absolute;
                top: 5px;
                left: 5px;
                opacity: 0;
                transform: scale(0.9);
                transition: opacity .2s, transform .2s;
              }
            }
            p{
              color: #949494;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin: 0;
              transition: color .2s;
            }
          }
        }
      }
      .stats{
        width: 105px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
        input{
          display: none;
          &:checked{
            & ~ span{
              background: $fluxoo-primary-color-hover;
              &::before{
                background: $fluxoo-primary-color;
                left: calc(100% - 25px);
              }
              &::after{
                content: 'Ativo';
                color: $fluxoo-primary-color;
              }
            }
          }
        }
        span{
          width: 43px;
          height: 11px;
          background: #949494;
          border-radius: 12px;
          position: relative;
          transition: background .2s;
          &::before{
            content: '';
            width: 25px;
            height: 25px;
            background: #D5D5D5;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            left: 0;
            transition: background .2s, left .2s;
          }
          &::after{
            content: 'Inativo';
            position: absolute;
            left: calc(100% + 10px);
            bottom: -4px;
            color: #949494;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            transition: color .2s;
          }
        }
      }
    }
    nav{
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 32px;
      border-radius: 16px;
      background: #FFF;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      position: sticky;
      bottom: 5px;
      column-gap: 20px;
      margin: auto 0 0 0;
      input{
        border: none;
        cursor: pointer;
        &:first-child{
          width: 100%;
          max-width: 464px;
          height: 60px;
          border-radius: 30px;
          background: $fluxoo-primary-color;
          color: #FFF;
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: background .2s;
          &:hover{
            background: $fluxoo-primary-color-hover;
          }
        }
        &:last-child{
          color: #737373;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: unset;
          transition: color .2s;
          &:hover{
            color: $fluxoo-primary-color;
          }
        }
      }
    }
  }

  @media(max-width: 425px){
    #create-models{
      padding: 25px 0 120px 0;
      nav{
        animation: enterToBottom ease 0.9s;
        width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
        padding: 15px;
        box-sizing: border-box;
        z-index: 4;
      }
    }
  }
</style>
