<template>
    <div id="create-alerts">
      <section class="quantity">
        <h1><strong>Título do alerta</strong> (obrigatório)</h1>
        <input :maxlength="100" type="text" placeholder="Ex: Envio de informações de folha" v-model="payload.title">
        <p v-if="payload.title">{{ payload.title.length }}/100</p>
        <p v-else>0/100</p>
      </section>
      <section>
        <h1><strong>Data limite</strong> (obrigatório)</h1>
        <h2>Prazo máximo para o documento ser apreciado.</h2>
        <label class="date-picker-label" for="limit_date" @click="isLimitDateModalOpen = true">
          <input type="text" placeholder="Selecione uma data" name="limit_date" :value="formattedLimitDate ? formattedLimitDate : ''" id="limit_date" readonly>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.5 9.08984H20.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.6937 13.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.6937 16.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.9945 13.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.9945 16.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.29529 13.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.29529 16.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </label>
      </section>
      <section class="quantity">
        <h1><strong>Descrição</strong> (obrigatório)</h1>
        <h2>Descreva em uma frase curta as informações desse alerta.</h2>
        <textarea name="" id="" cols="30" rows="10" placeholder="Ex: As informações para a folha precisam ser enviadas até o dia 20 de cada mês." v-model="payload.description"></textarea>
      </section>
      <section class="spacing">
        <h1><strong>Status</strong> (obrigatório)</h1>
        <label for="stats">
          <input type="checkbox" name="stats" id="stats" v-model="payload.status">
          <span></span>
        </label>
      </section>
      <nav>
        <input v-if="!isCreatingAlert" type="button" value="Enviar" @click="createAlert" :disabled="!payload.title || !payload.limit_date || !payload.description">
        <SpinnerLoaderButton v-else :height="60" :maxWidth="464" />
        <input type="button" value="Cancelar" @click="$router.go(-1)">
      </nav>
      <MyModal :width="'341px !important'" :height="'517px !important'" :className="'date-limit'" :isOpen="isLimitDateModalOpen" :toggleModal="(v: boolean) => isLimitDateModalOpen = v" :zIndex="100">
        <div class="modal-date-title">
          <h1>Data limite</h1>
          <h2>Prazo máximo para ser aceito.</h2>
        </div>
        <MyDatePicker :min="{ day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() }" :name="'limit'" :setValue="(date) => selectedLimitDate = date" />
        <input type="button" value="Aplicar data" @click="payload.limit_date = selectedLimitDate; isLimitDateModalOpen = false">
      </MyModal>
    </div>
</template>

<script setup lang="ts">
import AlertModel from '@/models/AlertModel'
import { computed, ref } from 'vue'
import MyModal from '@/components/modal/MyModal.vue'
import MyDatePicker from '@/components/date/MyDatePicker.vue'
import { useAxios } from '@/api/axios'
import { toast } from 'vue3-toastify'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'

const axios = useAxios()
const payload = ref<Partial<AlertModel>>({})
const selectedLimitDate = ref('')
const isLimitDateModalOpen = ref(false)
const isCreatingAlert = ref(false)
const formattedLimitDate = computed(() => payload.value.limit_date ? formatDate(payload.value.limit_date) : undefined)

function createAlert () {
  isCreatingAlert.value = true

  axios.post('/api/alerts', { ...payload.value, status: payload.value.status ? 'active' : 'inactive' })
    .then(() => {
      payload.value = {}
      toast.success('Alerta criado com sucesso')
    })
    .finally(() => isCreatingAlert.value = false)
}

const formatDate = (date: string) => {
  const [year, mouth, day] = date.split('-')
  return `${day}/${mouth}/${year}`
}
</script>

<style lang="scss">
  #create-alerts{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 28px;
    padding: 32px 0 0 0;
    section{
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      &.quantity{
        input{
          padding: 15px 80px 15px 20px !important;
        }
      }
      .date-picker-label{
        position: relative;
        display: flex;
        align-items: center;
        width:100%;
        input{
          display: flex;
          cursor: pointer;
          color: $fluxoo-primary-color;
        }
        svg{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          stroke: #090909;
          position: absolute;
          right: 22px;
        }
      }
      h1{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 13px 0;
        text-align: start;
        strong{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      h2{
        color: #6F6F6F;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        margin: -13px 0 13px 0;
      }
      input[type="text"]{
        width: 100%;
        height: 55px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: $fluxoo-primary-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        transition: border-color .2s, color .2s, background .2s;
        &:focus{
          outline: none;
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
      }
      textarea{
        width: 100%;
        height: 127px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: $fluxoo-primary-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        resize: none;
        transition: border-color .2s, color .2s, background .2s;
        &:focus{
          outline: none;
          border: 2px solid $fluxoo-primary-color;
          padding: 14px 19px;
          &::placeholder{
            color: transparent;
          }
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
        &::-webkit-scrollbar{
          width: 0;
        }
      }
      p{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        position: absolute;
        top: 57px;
        right: 20px;
      }
      label{
        width: 105px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
        input{
          display: none;
          &:checked{
            & ~ span{
              background: $fluxoo-primary-color-hover;
              &::before{
                background: $fluxoo-primary-color;
                left: calc(100% - 25px);
              }
              &::after{
                content: 'Ativo';
                color: $fluxoo-primary-color;
              }
            }
          }
        }
        span{
          width: 43px;
          height: 11px;
          background: #949494;
          border-radius: 12px;
          position: relative;
          transition: background .2s;
          &::before{
            content: '';
            width: 25px;
            height: 25px;
            background: #D5D5D5;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            left: 0;
            transition: background .2s, left .2s;
          }
          &::after{
            content: 'Inativo';
            position: absolute;
            left: calc(100% + 10px);
            bottom: -4px;
            color: #949494;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            transition: color .2s;
          }
        }
      }
    }
    nav{
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 32px;
      border-radius: 16px;
      background: #FFF;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      position: sticky;
      bottom: 5px;
      column-gap: 20px;
      margin: auto 0 0 0;
      input{
        border: none;
        cursor: pointer;
        &:first-child{
          width: 100%;
          max-width: 464px;
          height: 60px;
          border-radius: 30px;
          background: $fluxoo-primary-color;
          color: #FFF;
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: background .2s;
          &:hover{
            background: $fluxoo-primary-color-hover;
          }
        }
        &:last-child{
          color: #737373;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: unset;
          transition: color .2s;
          &:hover{
            color: $fluxoo-primary-color;
          }
        }
      }
    }
    .modal{
      &.date-limit{
        .modal__content{
          padding: 15px 28px;
          box-sizing: border-box;
          overflow-y: auto;
          &::-webkit-scrollbar{
            width: 0;
          }
          button{
            display: flex;
            top: 26px;
            right: 28px;
          }
          .modal-date-title{
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: start;
            margin: 0 0 25px 0;
            h1{
              color: #090909;
              font-family: 'Nunito', sans-serif;
              font-size: 32px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              text-align: start;
              margin: 0;
            }
            h2{
              color: #6F6F6F;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 2px;
              text-align: start;
              margin: 0;
            }
          }
          input[type='button']{
            width: 100%;
            min-height: 55px;
            border-radius: 30px;
            border: none;
            background: $fluxoo-primary-color;
            color: #FFF;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 8px 0 0 0;
            transition: background .2s;
            cursor: pointer;
            &:disabled{
              background: #F1F1F1 !important;
              color: #6F6F6F !important;
              cursor: not-allowed !important;
            }
            &:hover{
              background: $fluxoo-primary-color-hover;
            }
          }
        }
      }
    }
  }

  @media(max-width: 425px){
    #create-alerts{
      padding: 25px 0 120px 0;
      nav{
        animation: enterToBottom ease 0.9s;
        width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
        padding: 15px;
        box-sizing: border-box;
        z-index: 4;
      }
      .modal {
        padding: unset;
        &.date-limit{
          padding: 0 10px;
          .modal__content{
            border-radius: 28px;
            padding: 15px;
            .date-picker{
              .select-day{
                .date{
                  gap: 0;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
