enum DocumentLogActionEnum {
    CREATION = 'creation',
    VIEW = 'view',
    TRANSFER = 'transfer',
    EXTENSION = 'extension',
    COMPLETION = 'completion',
    REOPENING = 'reopening',
}

export const documentLogActionTranslations: Record<DocumentLogActionEnum, string> = {
  [DocumentLogActionEnum.CREATION]: 'Criou',
  [DocumentLogActionEnum.VIEW]: 'Visualizou',
  [DocumentLogActionEnum.EXTENSION]: 'Prorrogou',
  [DocumentLogActionEnum.TRANSFER]: 'Transferiu',
  [DocumentLogActionEnum.COMPLETION]: 'Finalizou',
  [DocumentLogActionEnum.REOPENING]: 'Reabriu'
}

export default DocumentLogActionEnum
