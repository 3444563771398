<template>
    <label class="mySelect" :class="{open: isSelectOpen}" :for="props.for || ''">
        <input
          type="text"
          placeholder="Selecione"
          :value="currentValue"
          @click="isSelectOpen ? closeSelect() : isSelectOpen = true"
          @blur="closeSelect" :id="props.for || 'my-select'"
          :name="name" readonly
          autocomplete="off"/>
        <div>
          <ul>
              <slot/>
          </ul>
        </div>
    </label>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue'

const props = defineProps<{
  name: string;
  currentValue: string | null | undefined;
  for?: string;
}>()

/* SCRIPT SELECT */
const isSelectOpen = ref<boolean>(false)

const closeSelect = () => {
  setTimeout(() => {
    isSelectOpen.value = false
  }, 80)
}
</script>

<style lang="scss">

    .mySelect{
      position: relative;
      input{
        width: 100%;
        height: 55px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        transition: border-color .2s, color .2s, background .2s;
        cursor: pointer;
        &:focus{
          outline: none;
        }
        &::placeholder{
          color: #090909 !important;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
      }
      div{
        min-width: 100%;
        max-height: 233px;
        padding: 5px 10px 5px 0;
        display: none;
        flex-direction: column;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10);
        box-sizing: border-box;
        position: absolute;
        top: calc(100% + 5px);
        z-index: 1;
        ul{
          width: 100%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding: 15px 20px;
          margin: 0;
          overflow-y: auto;
          row-gap: 24px;
          &::-webkit-scrollbar{
            width: 8px;
            background: #FFF;
          }
          &::-webkit-scrollbar-thumb{
            background: $fluxoo-primary-color;
            border-radius: 8px;
          }
          li{
            display: flex;
            align-items: center;
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: start;
            transition: color .2s;
            cursor: pointer;
            &:hover{
              color: $fluxoo-primary-color;
              text-decoration: underline
            }
          }
        }
      }
      &.open{
        &::before{
          top: 24px;
          border-color: $fluxoo-primary-color;
          transform: rotate(405deg);
        }
        input{
          border: 2px solid $fluxoo-primary-color !important;
          color: $fluxoo-primary-color !important;
          padding: 14px 19px !important;
          &::placeholder{
            color: $fluxoo-primary-color !important;
          }
        }
        div{
          display: flex;
        }
      }
      &::before{
        content: "";
        width: 10px;
        height: 10px;
        border-left: 2px solid #292D32;
        border-top: 2px solid #292D32;
        border-radius: 2px;
        position: absolute;
        top: 19px;
        right: 30px;
        transform: rotate(225deg);
        transition: border-color .2s;
      }
    }
</style>
