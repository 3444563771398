<template>
  <div class="item">
    <input type="radio" :id="id" :name="name" :checked="JSON.stringify(props.modelValue) === JSON.stringify(props.value)" :value="JSON.stringify(value)" @change="handleChange">
    <label :for="id">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
          <g clip-path="url(#clip0_388_3128)">
            <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
          </g>
          <defs>
            <clipPath id="clip0_388_3128">
              <rect width="12.5" height="10" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </span>
      <p>
        <slot/>
      </p>
    </label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ name: string, id: string, value: unknown, modelValue: unknown }>()

const emit = defineEmits(['update:modelValue', 'change']);

function handleChange(event: Event) {
  const target = event.target as HTMLInputElement;
  
  emit('update:modelValue', JSON.parse(target.value));
  emit('change', target.checked);
}
</script>