<template>
  <main id="admin">
    <div class="splash" :class="{'close': splashOpen}">
      <svg width="87" height="98" viewBox="0 0 87 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1035_7416)">
        <path d="M0 12.2118C0 5.46742 5.48609 0 12.2535 0H74.7465C81.5139 0 87 5.46742 87 12.2118C87 18.9563 81.5139 24.4237 74.7465 24.4237H12.2535C5.48609 24.4237 0 18.9563 0 12.2118Z" fill="white"/>
        <path d="M0 42.7414C0 35.9969 5.48609 30.5295 12.2535 30.5295H52.6901C59.4576 30.5295 64.9437 35.9969 64.9437 42.7414C64.9437 49.4858 59.4576 54.9532 52.6901 54.9532H12.2535C5.48609 54.9532 0 49.4858 0 42.7414Z" fill="white"/>
        <path d="M24.507 73.2709C24.507 66.5265 19.021 61.0591 12.2535 61.0591C5.48609 61.0591 0 66.5265 0 73.2709V85.7881C0 92.5325 5.48609 97.9999 12.2535 97.9999C19.021 97.9999 24.507 92.5325 24.507 85.7881V73.2709Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_1035_7416">
        <rect width="87" height="98" fill="white"/>
        </clipPath>
        </defs>
      </svg>
      <svg width="114" height="24" viewBox="0 0 114 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1035_7393)">
        <path d="M102.846 23.9217C101.22 23.9217 99.721 23.6626 98.3497 23.1445C96.9977 22.6264 95.8221 21.8972 94.8228 20.9569C93.8428 19.9974 93.0787 18.8845 92.5303 17.618C91.982 16.3515 91.7074 14.9698 91.7074 13.4731C91.7074 11.9763 91.982 10.5946 92.5303 9.32814C93.0787 8.04247 93.8428 6.92943 94.8228 5.98918C95.8221 5.04893 96.9977 4.3197 98.3497 3.80159C99.721 3.28347 101.22 3.02441 102.846 3.02441C104.473 3.02441 105.962 3.28347 107.314 3.80159C108.685 4.3197 109.861 5.04893 110.841 5.98918C111.84 6.92943 112.614 8.04247 113.163 9.32814C113.711 10.5946 113.986 11.9763 113.986 13.4731C113.986 14.9698 113.711 16.3515 113.163 17.618C112.614 18.8845 111.84 19.9974 110.841 20.9569C109.861 21.8972 108.685 22.6264 107.314 23.1445C105.962 23.6626 104.473 23.9217 102.846 23.9217ZM102.846 18.9132C103.571 18.9132 104.228 18.779 104.816 18.5103C105.403 18.2224 105.903 17.8291 106.315 17.3301C106.726 16.8312 107.039 16.2555 107.255 15.6031C107.471 14.9314 107.578 14.2214 107.578 13.4731C107.578 12.7247 107.471 12.0242 107.255 11.3718C107.039 10.7002 106.726 10.1149 106.315 9.61598C105.903 9.11703 105.403 8.73329 104.816 8.46461C104.228 8.17677 103.571 8.03285 102.846 8.03285C102.122 8.03285 101.465 8.17677 100.877 8.46461C100.289 8.73329 99.7898 9.11703 99.3784 9.61598C98.9669 10.1149 98.6537 10.7002 98.4379 11.3718C98.2221 12.0242 98.1146 12.7247 98.1146 13.4731C98.1146 14.2214 98.2221 14.9314 98.4379 15.6031C98.6537 16.2555 98.9669 16.8312 99.3784 17.3301C99.7898 17.8291 100.289 18.2224 100.877 18.5103C101.465 18.779 102.122 18.9132 102.846 18.9132Z" fill="white"/>
        <path d="M81.3493 23.6751C79.723 23.6751 78.224 23.416 76.8525 22.8979C75.5005 22.3798 74.3249 21.6506 73.3256 20.7103C72.3459 19.7508 71.5817 18.6379 71.0331 17.3714C70.4846 16.1049 70.2102 14.7232 70.2102 13.2265C70.2102 11.7297 70.4846 10.3481 71.0331 9.08156C71.5817 7.79589 72.3459 6.68285 73.3256 5.7426C74.3249 4.80234 75.5005 4.07312 76.8525 3.555C78.224 3.03689 79.723 2.77783 81.3493 2.77783C82.9756 2.77783 84.4647 3.03689 85.8167 3.555C87.188 4.07312 88.3636 4.80234 89.3435 5.7426C90.3428 6.68285 91.1171 7.79589 91.6654 9.08156C92.2138 10.3481 92.4884 11.7297 92.4884 13.2265C92.4884 14.7232 92.2138 16.1049 91.6654 17.3714C91.1171 18.6379 90.3428 19.7508 89.3435 20.7103C88.3636 21.6506 87.188 22.3798 85.8167 22.8979C84.4647 23.416 82.9756 23.6751 81.3493 23.6751ZM81.3493 18.6667C82.0742 18.6667 82.7306 18.5324 83.3185 18.2637C83.9063 17.9758 84.4059 17.5825 84.8174 17.0835C85.2289 16.5846 85.5421 16.0089 85.7579 15.3565C85.9737 14.6848 86.0812 13.9749 86.0812 13.2265C86.0812 12.4781 85.9737 11.7776 85.7579 11.1252C85.5421 10.4536 85.2289 9.86835 84.8174 9.3694C84.4059 8.87044 83.9063 8.48671 83.3185 8.21803C82.7306 7.93019 82.0742 7.78627 81.3493 7.78627C80.6243 7.78627 79.9679 7.93019 79.3801 8.21803C78.7923 8.48671 78.2927 8.87044 77.8812 9.3694C77.4697 9.86835 77.1562 10.4536 76.9407 11.1252C76.7251 11.7776 76.6174 12.4781 76.6174 13.2265C76.6174 13.9749 76.7251 14.6848 76.9407 15.3565C77.1562 16.0089 77.4697 16.5846 77.8812 17.0835C78.2927 17.5825 78.7923 17.9758 79.3801 18.2637C79.9679 18.5324 80.6243 18.6667 81.3493 18.6667Z" fill="white"/>
        <path d="M52.6681 23.3012L58.0466 14.7236C57.1257 14.2822 56.3419 13.7353 55.6953 13.0829C55.0487 12.4305 54.5491 11.6821 54.1964 10.8377C53.8633 9.97418 53.6968 9.0243 53.6968 7.98808V3.15234H60.0452V8.3047C60.0452 8.63095 60.1039 8.92833 60.2215 9.19701C60.3391 9.46569 60.4958 9.69596 60.6917 9.88783C60.9073 10.0605 61.1424 10.1853 61.3971 10.262L65.747 3.15234H72.7713L67.4516 11.5285C68.4901 11.8931 69.3816 12.4112 70.1262 13.0829C70.8708 13.7353 71.4389 14.5124 71.8308 15.4144C72.2227 16.3163 72.4186 17.3238 72.4186 18.4367V23.3012H66.0702V18.2064C66.0702 17.6308 65.8939 17.1223 65.5412 16.6809C65.1885 16.2395 64.7575 15.9709 64.248 15.8749L59.6925 23.3012H52.6681Z" fill="white"/>
        <path d="M44.5563 23.6466C42.7537 23.6466 41.1568 23.3108 39.7657 22.6392C38.3941 21.9484 37.3164 20.9697 36.5327 19.7032C35.749 18.4367 35.3571 16.9399 35.3571 15.2129V3.15234H41.5879V15.4719C41.5879 16.086 41.7054 16.6425 41.9406 17.1414C42.1953 17.6211 42.5382 17.9953 42.9692 18.264C43.4199 18.5327 43.9391 18.667 44.527 18.667C45.1343 18.667 45.6536 18.5327 46.0847 18.264C46.5157 17.9953 46.8488 17.6211 47.0839 17.1414C47.3386 16.6425 47.466 16.086 47.466 15.4719V3.15234H53.6969V15.2129C53.6969 16.9399 53.3049 18.4367 52.5212 19.7032C51.7375 20.9697 50.6598 21.9484 49.2882 22.6392C47.9167 23.3108 46.3394 23.6466 44.5563 23.6466Z" fill="white"/>
        <path d="M30.3648 23.5478C28.9736 23.5478 27.7589 23.26 26.7204 22.6843C25.6819 22.1086 24.8786 21.3506 24.3103 20.4103C23.7421 19.4508 23.458 18.3955 23.458 17.2441V3.39893H29.8064V17.3017C29.8064 17.6662 29.9338 17.9925 30.1885 18.2803C30.4628 18.549 30.7959 18.6833 31.1878 18.6833H36.625V23.5478H30.3648Z" fill="white"/>
        <path d="M81.3703 19.4357C84.8486 19.4357 87.6683 16.6741 87.6683 13.2676C87.6683 9.86113 84.8486 7.09961 81.3703 7.09961C77.892 7.09961 75.0723 9.86113 75.0723 13.2676C75.0723 16.6741 77.892 19.4357 81.3703 19.4357Z" fill="white"/>
        <path d="M102.699 19.4357C106.178 19.4357 108.998 16.6741 108.998 13.2676C108.998 9.86113 106.178 7.09961 102.699 7.09961C99.2212 7.09961 96.4015 9.86113 96.4015 13.2676C96.4015 16.6741 99.2212 19.4357 102.699 19.4357Z" fill="white"/>
        <path d="M101.524 12.0342H82.6298C81.9342 12.0342 81.3702 12.5865 81.3702 13.2678C81.3702 13.9491 81.9342 14.5014 82.6298 14.5014H101.524C102.22 14.5014 102.783 13.9491 102.783 13.2678C102.783 12.5865 102.22 12.0342 101.524 12.0342Z" fill="#079F72"/>
        <path d="M104.366 12.5962C104.811 12.9287 104.806 13.5881 104.355 13.9134L100.529 16.668C99.9712 17.0692 99.186 16.6759 99.1911 15.9987L99.2373 10.429C99.2431 9.75184 100.035 9.37115 100.586 9.78112L104.366 12.5962Z" fill="#079F72"/>
        <path d="M0.0217285 17.9409C0.0217285 16.2377 1.43158 14.8569 3.17073 14.8569C4.90988 14.8569 6.31974 16.2377 6.31974 17.9409V20.4904C6.31974 22.1937 4.90988 23.5744 3.17073 23.5744C1.43158 23.5744 0.0217285 22.1937 0.0217285 20.4904V17.9409Z" fill="white"/>
        <path d="M0.0217285 10.5406C0.0217285 8.83728 1.43158 7.45654 3.17073 7.45654H12.6597C14.3988 7.45654 15.8087 8.83728 15.8087 10.5406C15.8087 12.2438 14.3988 13.6246 12.6597 13.6246H3.17073C1.43158 13.6246 0.0217285 12.2438 0.0217285 10.5406Z" fill="white"/>
        <path d="M0.236579 3.19309C0.240039 1.48981 1.6527 0.111787 3.39184 0.115241L18.0872 0.14386C19.8263 0.147232 21.2333 1.53076 21.2299 3.23396C21.2265 4.93724 19.8138 6.31526 18.0746 6.31189L3.37931 6.28318C1.64016 6.27981 0.233118 4.89637 0.236579 3.19309Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_1035_7393">
        <rect width="114" height="24" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>
    <RouterView/>
    <AdminLayoutMenu/>
    <InstallPWAModal />
  </main>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import AdminLayoutMenu from './components/AdminLayoutMenu.vue';
import { useAxios } from '@/api/axios';
import { AxiosResponse } from 'axios';
import { user } from '@/global/states/GlobalState';
import router from '@/router';
import { UserModel } from '@/models/UserModel';
import InstallPWAModal from '@/components/modal/pwa/InstallPWAModal.vue';
import { checkLocalStorageNotification } from '@/global/states/ModalState';


const splashOpen = ref<boolean>(false)

const axios = useAxios()

onMounted(() => {
  axios.get('/api/user').then((response: AxiosResponse<UserModel>) => {
    user.value = {
      ...response.data,
      can: (perm) => {
        return response.data.roles.some((role) => {
          return role.permissions.some((permission) => {
            return permission.name === perm
          })
        })
      },
      hasRoles: (roles: string[]) => {
        return roles.every((roleName: string) => {
          return response.data.roles.some((role: any) => {
            return role.name === roleName
          })
        })
      }
    }
  }).catch(() => {
    router.push({ name: 'login' })
  })

  checkLocalStorageNotification();

  setTimeout(() => {
    splashOpen.value = true
  }, 1000)
})

</script>

<style lang="scss">
    #admin{
        height: 100%;
        min-height: 100dvh;
        position: relative;
        padding: 0 15px 0 91px;
        display: flex;
        justify-content: center;
    }

    @media(max-width: 425px){
        #admin{
            padding: 0 15px;
            display: flex;
        }
    }
</style>
