enum DocumentTypeEnum {
    GENERAL_DOCUMENT = 'general_document',
    CONTRACT_AMENDMENT = 'contract_amendment',
    TERMINATION = 'termination',
}

export const documentTypeTranslations: Record<DocumentTypeEnum, string> = {
  [DocumentTypeEnum.GENERAL_DOCUMENT]: 'Documento Geral',
  [DocumentTypeEnum.CONTRACT_AMENDMENT]: 'Contratos e Aditivos',
  [DocumentTypeEnum.TERMINATION]: 'Rescisão'
}

export default DocumentTypeEnum
