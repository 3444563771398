<template>
    <div class="text-editor">
        <div class="text-editor__menu-bar" v-if="editor">
            <button class="font mySelect" :class="{open: isFontSizeSelectOpen}" @click="isFontSizeSelectOpen = !isFontSizeSelectOpen;" @blur="closeSelect('font-size')">
                {{ `H${selectedSize}` ?? 'Normal' }}
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path d="M9.16801 13.7962L7.10698 11.6962L5.84854 10.4074C5.59212 10.1465 5.24458 10 4.88223 10C4.51988 10 4.17234 10.1465 3.91592 10.4074L0.590031 13.7962C0.153427 14.2411 0.468038 15 1.078 15H8.68004C9.29642 15 9.60461 14.2411 9.16801 13.7962Z"/>
                    <path d="M8.6783 0H1.0791C0.46295 0 0.154874 0.758914 0.591314 1.20379L3.91596 4.59274C4.44868 5.13575 5.31514 5.13575 5.84786 4.59274L7.11225 3.30389L9.17251 1.20379C9.60253 0.758914 9.29445 0 8.6783 0Z"/>
                </svg>
                <div>
                  <ul>
                      <li v-for="(level, index) in [1, 2, 3, 4, 5, 6]" :key="index" @click="selectedSize = level; editor.chain().focus().toggleHeading({ level: level }).run()" :class="{ 'is-active': editor.isActive('heading', { level: level }) }">H{{ level }}</li>
                  </ul>
                </div>
            </button>
            <button class="font mySelect" :class="{open: isFontFamilySelectOpen}" @click="isFontFamilySelectOpen = !isFontFamilySelectOpen;" @blur="closeSelect('font-family')">
                {{ selectedFont }}
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path d="M9.16801 13.7962L7.10698 11.6962L5.84854 10.4074C5.59212 10.1465 5.24458 10 4.88223 10C4.51988 10 4.17234 10.1465 3.91592 10.4074L0.590031 13.7962C0.153427 14.2411 0.468038 15 1.078 15H8.68004C9.29642 15 9.60461 14.2411 9.16801 13.7962Z"/>
                    <path d="M8.6783 0H1.0791C0.46295 0 0.154874 0.758914 0.591314 1.20379L3.91596 4.59274C4.44868 5.13575 5.31514 5.13575 5.84786 4.59274L7.11225 3.30389L9.17251 1.20379C9.60253 0.758914 9.29445 0 8.6783 0Z"/>
                </svg>
                <div>
                  <ul>
                    <li v-for="(font, index) in fonts" :key="index" @click="selectedFont = font; editor.chain().focus().setFontFamily(font).run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: font }) }">{{ font }}</li>
                  </ul>
                </div>
            </button>
            <button class="bold" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                B
            </button>
            <button class="italic" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                I
            </button>
            <button class="underline" @click="editor.chain().focus().toggleUnderline().run()" :disabled="!editor.can().chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
                U
            </button>
            <button class="strike-through" @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                S
            </button>
            <!-- <div class="button-group">
                <button @click="editor.chain().focus().setFontFamily('Inter').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'Inter' }) }">
                Inter
                </button>
                <button @click="editor.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'Comic Sans MS, Comic Sans' }) }">
                Comic Sans
                </button>
                <button @click="editor.chain().focus().setFontFamily('serif').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'serif' }) }">
                Serif
                </button>
                <button @click="editor.chain().focus().setFontFamily('monospace').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'monospace' }) }">
                Monospace
                </button>
                <button @click="editor.chain().focus().setFontFamily('cursive').run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'cursive' }) }">
                Cursive
                </button>
                <button @click="editor.chain().focus().unsetFontFamily().run()">
                Unset font family
                </button>
            </div> -->
        </div>
        <EditorContent class="text-editor--input" :editor="editor" />
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'

const props = defineProps<{value?: string, setValue:(value: string) => void}>()
const editor = ref<Editor>()
const selectedFont = ref<string>('Inter')
const selectedSize = ref<number>(2)
const isFontSizeSelectOpen = ref<boolean>(false)
const isFontFamilySelectOpen = ref<boolean>(false)

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      TextStyle,
      FontFamily,
      StarterKit,
      Underline,
      Strike
    ],
    content: `<p>${props.value ?? ''}</p>`,
    onUpdate: () => {
      props.setValue(editor.value!.getHTML())
    }
  })
  editor.value.chain().focus().toggleHeading({ level: 2 }).run()
  editor.value.chain().focus().setFontFamily('Inter').run()
})

const fonts = [
  'Sans-serif',
  'Inter',
  'Arial',
  'Helvetica',
  'Times New Roman',
  'Georgia',
  'Courier New',
  'Verdana',
  'Tahoma',
  'Garamond',
  'Palatino',
  'Bookman',
  'Comic Sans MS',
  'Trebuchet MS',
  'Arial Black',
  'Impact',
  'Lucida Console'
]

const closeSelect = (select: string) => {
  setTimeout(() => {
    if (select == 'font-size') {
      isFontSizeSelectOpen.value = false
    } else if (select == 'font-family') {
      isFontFamilySelectOpen.value = false
    }
  }, 80)
}
</script>

<style lang="scss">

  .text-editor{
    width: 100%;
    height: 350px;
    border: 1px solid #D3D3D3;
    border-radius: 24px;
    padding-bottom:1rem;
    h1,h2,h3,h4,h5,h6{
      font-family: unset !important;
      font-weight:unset;
      color: unset !important;
      margin: unset !important
    }
    strong{
      font-size:unset !important;
    }
    h1 {
      font-size: 2em !important;
    }
    h2 {
      font-size: 1.5em !important;
    }
    h3 {
      font-size: 1.17em !important;
    }
    h4 {
      font-size: 1em !important;
    }
    h5 {
      font-size: 0.83em !important;
    }
    h6 {
      font-size: 0.67em !important;
    }
    &:focus{
        border: 2px solid $fluxoo-primary-color;
    }
    &__menu-bar{
      display:flex;
      align-items: center;
      gap:1rem;
      padding:1rem 2rem;
      border-bottom:1px solid var(--cinza-medio, #D3D3D3);
      button{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 1.375rem;
        text-align: center;
        justify-content: center;
        transition: color .2s;
        &.is-active{
          color: $fluxoo-primary-color
        }
        &:hover{
          color: $fluxoo-primary-color;
          svg{
            fill: $fluxoo-primary-color;
          }
        }
        svg{
          fill: #090909;
          transition: fill .2s;
        }
      }
      .font{
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 4px;
          font-size: 1rem;
          font-weight: 400;
          padding-right:1rem;
          top: unset !important;
          bottom: unset !important;
          left: unset !important;
          right: unset !important;
          svg{
              width: 0.56163rem;
              height: 0.9375rem;
          }
          &::before{
              display:none
          }
          div{
            max-height: 270px;
          }
      }
      .bold{
          font-weight: 700 !important;
          width: 20px !important;
      }
      .italic{
          font-style: italic !important;
          font-weight: 500 !important;
          width: 20px !important;
      }
      .underline{
          font-weight: 100 !important;
          line-height: normal !important;
          text-decoration-line: underline !important;
          width: 20px !important;
      }
      .strike-through{
          font-weight: 400 !important;
          text-decoration-line:line-through !important;
          width: 20px !important;
      }
    }
    &--input{
      height: 100%;
      max-height: 281px;
      box-sizing: border-box;
      .tiptap{
        height: 100%;
        text-align: start;
        padding:0 2rem;
        padding-top:1rem;
        overflow: auto;
        border:1px solid transparent;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        &:focus{
          outline: none;
        }
        &::-webkit-scrollbar {
            width: 0.4125rem !important;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(189, 197, 209, 0.6);
            visibility: hidden;
        }

        &:hover::-webkit-scrollbar-thumb {
            visibility: visible;
            border-radius: 15rem;
        }
      }
    }
  }

  @media(max-width: 446px){
    .text-editor{
      &__menu-bar{
        padding: calc(1rem + 10px) 1rem 1rem !important;
      }
      &--input{
        max-height: 265px;
        .tiptap{
          padding: 0 1rem;
          padding-top: 1rem;
          max-height: 265px;
        }
      }
    }
  }
</style>
