<template>
  <main id="login">
    <section></section>
    <section>
      <img src="@/assets/images/prefeitura-caninde.png" alt="Logo Prefeitura de Canindé.">
      <h1>Bem-vindo (a) de volta!</h1>
      <form @submit="$event.preventDefault()" action="">
        <section>
          <label for="email">Digite seu e-mail  <strong>*</strong></label>
          <input autocomplete='off' v-model="loginForm.email" type="text" placeholder="ex: joaosasilva@gmail.com" name="email" id="email">
        </section>
        <section>
          <label for="password">Digite sua senha  <strong>*</strong></label>
          <input autocomplete='off' v-model="loginForm.password" :type="showPassword ? 'text':'password'" placeholder="ex: 123456" name="password" id="password">
          <button type="button" @click="showPassword = !showPassword">
            <svg v-if="showPassword" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="vuesax/linear/eye">
              <g id="eye">
              <path id="Vector" d="M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.41998 13.9799 8.41998 11.9999C8.41998 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="Vector_2" d="M12 20.2702C15.53 20.2702 18.82 18.1902 21.11 14.5902C22.01 13.1802 22.01 10.8102 21.11 9.40021C18.82 5.80021 15.53 3.72021 12 3.72021C8.47003 3.72021 5.18003 5.80021 2.89003 9.40021C1.99003 10.8102 1.99003 13.1802 2.89003 14.5902C5.18003 18.1902 8.47003 20.2702 12 20.2702Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              </g>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.47 14.53L2 22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.9998 2L14.5298 9.47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </section>
        <div class="remember-recover">
          <label for="remember">
            <input autocomplete='off' type="checkbox" name="remember" id="remember" v-model="loginForm.remember">
            <span></span>
            <p>Permanecer logado</p>
          </label>
          <RouterLink :to="{name: 'recover-password'}">Esqueci a senha</RouterLink>
        </div>
        <div class="recaptcha" style="display: flex; justify-content: center; margin: 0 0 15px 0">
          <Recaptcha :sitekey="recaptchaConfig.sitekey" :callback="(token) => loginForm.token = token" />
        </div>
        <input autocomplete='off' :disabled="!(loginForm.email && loginForm.password && loginForm.token)" @click="login" type="button" value="Entrar">
      </form>
      <svg xmlns="http://www.w3.org/2000/svg" width="131" height="28" viewBox="0 0 131 28" fill="none">
        <g clip-path="url(#clip0_689_4392)">
          <path d="M118.183 27.909C116.314 27.909 114.592 27.6067 113.016 27.0023C111.462 26.3978 110.111 25.547 108.963 24.4501C107.837 23.3307 106.959 22.0322 106.329 20.5546C105.699 19.077 105.383 17.4651 105.383 15.7189C105.383 13.9726 105.699 12.3607 106.329 10.8832C106.959 9.38321 107.837 8.08466 108.963 6.9877C110.111 5.89074 111.462 5.03997 113.016 4.43551C114.592 3.83104 116.314 3.52881 118.183 3.52881C120.052 3.52881 121.763 3.83104 123.317 4.43551C124.893 5.03997 126.243 5.89074 127.37 6.9877C128.518 8.08466 129.408 9.38321 130.038 10.8832C130.668 12.3607 130.983 13.9726 130.983 15.7189C130.983 17.4651 130.668 19.077 130.038 20.5546C129.408 22.0322 128.518 23.3307 127.37 24.4501C126.243 25.547 124.893 26.3978 123.317 27.0023C121.763 27.6067 120.052 27.909 118.183 27.909ZM118.183 22.0658C119.016 22.0658 119.771 21.9091 120.446 21.5956C121.121 21.2598 121.696 20.8009 122.168 20.2188C122.641 19.6367 123.001 18.9651 123.249 18.2039C123.497 17.4203 123.621 16.592 123.621 15.7189C123.621 14.8458 123.497 14.0286 123.249 13.2674C123.001 12.4838 122.641 11.8011 122.168 11.219C121.696 10.6369 121.121 10.1892 120.446 9.87571C119.771 9.53989 119.016 9.37199 118.183 9.37199C117.35 9.37199 116.596 9.53989 115.92 9.87571C115.245 10.1892 114.671 10.6369 114.198 11.219C113.725 11.8011 113.365 12.4838 113.117 13.2674C112.869 14.0286 112.746 14.8458 112.746 15.7189C112.746 16.592 112.869 17.4203 113.117 18.2039C113.365 18.9651 113.725 19.6367 114.198 20.2188C114.671 20.8009 115.245 21.2598 115.92 21.5956C116.596 21.9091 117.35 22.0658 118.183 22.0658Z" fill="#079F72"/>
          <path d="M93.4803 27.6209C91.6114 27.6209 89.889 27.3186 88.3129 26.7142C86.7594 26.1097 85.4084 25.2589 84.2601 24.162C83.1343 23.0426 82.2562 21.7441 81.6258 20.2665C80.9954 18.7889 80.6801 17.177 80.6801 15.4308C80.6801 13.6846 80.9954 12.0727 81.6258 10.5951C82.2562 9.09513 83.1343 7.79658 84.2601 6.69962C85.4084 5.60265 86.7594 4.75189 88.3129 4.14742C89.889 3.54296 91.6114 3.24072 93.4803 3.24072C95.3491 3.24072 97.0603 3.54296 98.6139 4.14742C100.19 4.75189 101.541 5.60265 102.667 6.69962C103.815 7.79658 104.705 9.09513 105.335 10.5951C105.965 12.0727 106.28 13.6846 106.28 15.4308C106.28 17.177 105.965 18.7889 105.335 20.2665C104.705 21.7441 103.815 23.0426 102.667 24.162C101.541 25.2589 100.19 26.1097 98.6139 26.7142C97.0603 27.3186 95.3491 27.6209 93.4803 27.6209ZM93.4803 21.7777C94.3133 21.7777 95.0676 21.621 95.7431 21.3076C96.4186 20.9717 96.9927 20.5128 97.4656 19.9307C97.9384 19.3486 98.2983 18.677 98.5463 17.9158C98.7943 17.1322 98.9178 16.3039 98.9178 15.4308C98.9178 14.5577 98.7943 13.7405 98.5463 12.9794C98.2983 12.1958 97.9384 11.513 97.4656 10.9309C96.9927 10.3488 96.4186 9.90108 95.7431 9.58762C95.0676 9.25181 94.3133 9.0839 93.4803 9.0839C92.6472 9.0839 91.8929 9.25181 91.2175 9.58762C90.542 9.90108 89.9678 10.3488 89.495 10.9309C89.0222 11.513 88.662 12.1958 88.4143 12.9794C88.1665 13.7405 88.0427 14.5577 88.0427 15.4308C88.0427 16.3039 88.1665 17.1322 88.4143 17.9158C88.662 18.677 89.0222 19.3486 89.495 19.9307C89.9678 20.5128 90.542 20.9717 91.2175 21.3076C91.8929 21.621 92.6472 21.7777 93.4803 21.7777Z" fill="#079F72"/>
          <path d="M60.5222 27.1848L66.7027 17.1775C65.6444 16.6625 64.7438 16.0245 64.0008 15.2633C63.2578 14.5022 62.6837 13.6291 62.2784 12.644C61.8956 11.6365 61.7042 10.5284 61.7042 9.31942V3.67773H68.9993V9.68882C68.9993 10.0694 69.0669 10.4164 69.202 10.7298C69.337 11.0433 69.5172 11.312 69.7423 11.5358C69.99 11.7373 70.2602 11.8828 70.5529 11.9724L75.5514 3.67773H83.6232L77.5102 13.4499C78.7036 13.8753 79.728 14.4797 80.5836 15.2633C81.4393 16.0245 82.0921 16.9312 82.5425 17.9834C82.9928 19.0357 83.218 20.211 83.218 21.5095V27.1848H75.9229V21.2408C75.9229 20.5692 75.7202 19.976 75.315 19.461C74.9097 18.9461 74.4144 18.6327 73.8289 18.5207L68.594 27.1848H60.5222Z" fill="#079F72"/>
          <path d="M51.2007 27.5877C49.1292 27.5877 47.2942 27.196 45.6956 26.4124C44.1195 25.6064 42.8811 24.4647 41.9805 22.9871C41.0799 21.5095 40.6295 19.7633 40.6295 17.7484V3.67773H47.7895V18.0506C47.7895 18.767 47.9246 19.4162 48.1948 19.9983C48.4875 20.558 48.8816 20.9945 49.3769 21.308C49.8948 21.6215 50.4914 21.7781 51.1669 21.7781C51.8648 21.7781 52.4616 21.6215 52.9569 21.308C53.4522 20.9945 53.835 20.558 54.1052 19.9983C54.3979 19.4162 54.5442 18.767 54.5442 18.0506V3.67773H61.7042V17.7484C61.7042 19.7633 61.2539 21.5095 60.3533 22.9871C59.4527 24.4647 58.2143 25.6064 56.6382 26.4124C55.0621 27.196 53.2496 27.5877 51.2007 27.5877Z" fill="#079F72"/>
          <path d="M34.8929 27.4724C33.2942 27.4724 31.8983 27.1365 30.7049 26.4649C29.5116 25.7933 28.5885 24.9089 27.9355 23.812C27.2825 22.6926 26.9561 21.4613 26.9561 20.118V3.96533H34.2512V20.1852C34.2512 20.6105 34.3975 20.9911 34.6902 21.327C35.0055 21.6404 35.3882 21.7971 35.8385 21.7971H42.0866V27.4724H34.8929Z" fill="#079F72"/>
          <path d="M93.5044 22.6748C97.5014 22.6748 100.742 19.453 100.742 15.4787C100.742 11.5045 97.5014 8.28271 93.5044 8.28271C89.5074 8.28271 86.2672 11.5045 86.2672 15.4787C86.2672 19.453 89.5074 22.6748 93.5044 22.6748Z" fill="#079F72"/>
          <path d="M118.014 22.6748C122.011 22.6748 125.252 19.453 125.252 15.4787C125.252 11.5045 122.011 8.28271 118.014 8.28271C114.017 8.28271 110.777 11.5045 110.777 15.4787C110.777 19.453 114.017 22.6748 118.014 22.6748Z" fill="#079F72"/>
          <path d="M116.663 14.0396H94.9519C94.1525 14.0396 93.5044 14.6839 93.5044 15.4788C93.5044 16.2736 94.1525 16.918 94.9519 16.918H116.663C117.463 16.918 118.111 16.2736 118.111 15.4788C118.111 14.6839 117.463 14.0396 116.663 14.0396Z" fill="white"/>
          <path d="M119.929 14.6957C120.441 15.0836 120.435 15.8529 119.916 16.2324L115.52 19.4461C114.879 19.9141 113.977 19.4553 113.983 18.6653L114.036 12.1673C114.043 11.3772 114.953 10.9331 115.586 11.4114L119.929 14.6957Z" fill="white"/>
          <path d="M0.0249634 20.931C0.0249634 18.9439 1.64506 17.333 3.64356 17.333C5.64205 17.333 7.26215 18.9439 7.26215 20.931V23.9054C7.26215 25.8925 5.64205 27.5034 3.64356 27.5034C1.64506 27.5034 0.0249634 25.8925 0.0249634 23.9054V20.931Z" fill="#079F72"/>
          <path d="M0.0249634 12.2972C0.0249634 10.3101 1.64506 8.69922 3.64356 8.69922H14.5476C16.546 8.69922 18.1662 10.3101 18.1662 12.2972C18.1662 14.2843 16.546 15.8952 14.5476 15.8952H3.64356C1.64506 15.8952 0.0249634 14.2843 0.0249634 12.2972Z" fill="#079F72"/>
          <path d="M0.271858 3.7251C0.275834 1.73794 1.89916 0.130255 3.89764 0.134285L20.7844 0.167674C22.7828 0.171608 24.3997 1.78573 24.3958 3.77279C24.3918 5.75995 22.7685 7.36764 20.7699 7.3637L3.88324 7.33022C1.88475 7.32629 0.267881 5.71226 0.271858 3.7251Z" fill="#079F72"/>
        </g>
        <defs>
          <clipPath id="clip0_689_4392">
            <rect width="131" height="28" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </section>
  </main>
  <InstallPWAModal />
  <Loader v-if="isPerformingRequest" :zIndex="99"/>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { isPerformingRequest, useAxios } from '@/api/axios'
import router from '@/router'
import Loader from '@/components/loader/Loader.vue'
import InstallPWAModal from '@/components/modal/pwa/InstallPWAModal.vue'
import Recaptcha from '@/components/google/Recaptcha.vue'
import { recaptchaConfig } from '@/global/config/recaptcha'

const showPassword = ref<boolean>(false)
const loginForm = ref<{email?:string, password?:string, token?: string, remember?:boolean}>({})
const axios = useAxios()

async function login () {
  axios.post('/login', loginForm.value)
    .then(() => {
      router.push({ name: 'admin.home' })
    })
}
</script>

<style lang="scss">

  #login{
    min-height: 100dvh;
    display: grid;
    grid-template-columns: 50% 50%;
    section{
      &:first-child{
        background-image: url('@/assets/images/background-2.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &:last-child{
        animation: enterContent ease .4s;
        max-height: 869px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        margin: auto 0;
        img{
          &:first-child{
            width: 111px;
            min-width: 111px;
            height: 145px;
            min-height: 145px;
          }
          &:last-child{
            width: 98px;
            min-width: 98px;
            height: 14px;
            min-height: 14px;
            margin: 20px 0 0 0;
          }
        }
        h1{
          font-size: 18px;
          font-family: 'Nunito', sans-serif;
          font-weight: 500;
          text-align: center;
          margin: 21px 0 0 0;
        }
        form{
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 387px;
          margin: 80px 0;
          section{
            display: flex;
            flex-direction: column;
            align-items: start;
            position: relative;
            background-image: unset;
            &:first-child{
              margin: 0 0 24px 0;
            }
            label{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin: 0 0 8px 0;
              strong{
                color: #EB1320;
                font-weight: 600;
              }
            }
            input{
              width: 100%;
              height: 55px;
              padding: 0 18px;
              border-radius: 16px;
              border: none;
              background: #F5F5F5;
              box-sizing: border-box;
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              &::placeholder{
                color: #090909;
                font-family: 'Inter', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
              }
              &:focus{
                outline: none;
                color: $fluxoo-primary-color;
                &::placeholder{
                  color: transparent;
                }
                & ~ button{
                  svg{
                    stroke: $fluxoo-primary-color !important;
                  }
                }
              }
            }
            svg{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              stroke: #090909;
              position: absolute;
              bottom: 15px;
              right: 20px;
              transition: stroke .2s;
              cursor: pointer;
              &:hover{
                stroke: $fluxoo-primary-color;
              }
            }
          }
          .recaptcha {
            padding-bottom: 1rem
          }
          .remember-recover{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 16px 0 32px 0;
            label{
              display: flex;
              align-items: center;
              column-gap: 10px;
              cursor: pointer;
              input{
                display: none;
                &:checked{
                  & ~ span{
                    border: 2px solid $fluxoo-primary-color;
                    background: $fluxoo-primary-color;
                  }
                  & ~ p{
                    color: $fluxoo-primary-color;
                  }
                }
              }
              span{
                width: 20px;
                min-width: 20px;
                height: 20px;
                min-height: 20px;
                position: relative;
                border: 2px solid #6F6F6F;
                border-radius: 6px;
                transition: all .2s;
                &::before{
                  content: '';
                  display: flex;
                  height: 10px;
                  width: 5px;
                  border-right: 2px solid #FFF;
                  border-bottom: 2px solid #FFF;
                  position: absolute;
                  left: 7px;
                  bottom: 6px;
                  transform: rotate(45deg);
                }
              }
              p{
                color: #6F6F6F;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0;
                transition: color .2s
              }
            }
            a{
              color: $fluxoo-primary-color;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-decoration-line: underline;
              margin: 0;
            }
          }
          input[type='button']{
            width: 100%;
            height: 60px;
            border: none;
            border-radius: 30px;
            background: $fluxoo-primary-color;
            color: #FFF;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 18px;
            transition: background .2s;
            cursor: pointer;
            &:hover{
              background: $fluxoo-primary-color-hover;
            }
            &:disabled{
              background: #F1F1F1 !important;
              color: #6F6F6F !important;
              cursor: not-allowed !important;
            }
          }
        }
      }
    }
  }

  @media(max-width: 830px){
    #login{
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 0;
      margin: 0;
      section{
        width: 100%;
        padding: 0 15px;
        &:first-child{
          display: none;
        }
        &:last-child{
          background: #FFF;
          border-radius: 30px;
          padding: 40px 15px 25px;
          form{
            margin: 70px 0;
            section{
              padding: 0;
            }
            .remember-recover{
              label{
                column-gap: 5px;
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 340px){
    #login{
      section{
        &:last-child{
          form{
            .remember-recover{
              label{
                column-gap: 5px;
                span{
                  width: 15px;
                  min-width: 15px;
                  height: 15px;
                  min-height: 15px;
                  &:before{
                    height: 10px;
                    width: 5px;
                    border-right: 1px solid #FFF;
                    border-bottom: 1px solid #FFF;
                    left: 4px;
                    bottom: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
